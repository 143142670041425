import "./App.css";
import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";

// Import pages
import Dashboard from "./pages/Dashboard";
import Analytics from "./pages/Analytics";
import Students from "./pages/Students";
import FeeTypes from "./pages/Types";
import OneStudent from "./pages/OneStudent";
import ReceiptInvoice from "./pages/ReceiptInvoice";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route path="/analytics">
          <Analytics />
        </Route>
        <Route path="/student">
          <Students />
        </Route>
        <Route path="/feetypes">
          <FeeTypes />
        </Route>
        <Route path="/one">
          <OneStudent />
        </Route>
        <Route path="/receipt-invoice">
          <ReceiptInvoice />
        </Route>
      </Switch>
    </>
  );
}

export default App;
