/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import { IoAlertCircle } from "react-icons/io5";
import { getTransactions, getClasses } from "../APi/Controller";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";

import { getSchool } from "../APi/Controller";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [totalToday, setTotalToday] = useState(0);
  const [termToday, setTermToday] = useState(0);
  const [YearToday, setYearToday] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [school, setSchool] = useState(null);
  const [classes, setClasses] = useState(null);
  const [classProps, setClassProp] = useState([]);

  let scho = useLocation().search;

  const getTerm = (eDate) => {
    if (eDate.getMonth() >= 0 && eDate.getMonth() < 3) {
      return 1;
    } else if (eDate.getMonth() >= 3 && eDate.getMonth() < 6) {
      return 2;
    } else if (eDate.getMonth() >= 6 && eDate.getMonth() < 9) {
      return 3;
    } else {
      return 4;
    }
  };

  const isSimilarDate = (someDate, helper) => {
    const today = new Date();
    const gotDate = new Date(someDate);

    switch (true) {
      case helper === "today":
        return (
          gotDate.getDate() === today.getDate() &&
          gotDate.getMonth() === today.getMonth() &&
          gotDate.getFullYear() === today.getFullYear()
        );
      case helper === "term":
        return getTerm(today) === getTerm(gotDate);
      case helper === "year":
        return gotDate.getFullYear() === today.getFullYear();
      default:
        break;
    }
  };

  useEffect(() => {
    if (scho) {
      getClasses(scho.substr(1)).then((response) => {
        let newClasses = [];
        response.map((cla) => newClasses.push(cla.name));
        setClassProp(newClasses);
        setClasses(response);
      });
      getSchool(scho.substr(1))
        .then((re) => {
          setSchool(re.data);
          getTransactions(re.data._id)
            .then((resp) => {
              setTransactions(resp);
              setLoading(false);
              let numDay = 0;
              let numYear = 0;
              let numTerm = 0;
              resp.forEach((e) => {
                if (isSimilarDate(e.createdAt, "today")) {
                  numDay = numDay + e.amount;
                }
                if (isSimilarDate(e.createdAt, "year")) {
                  numYear = numYear + e.amount;
                }
                if (isSimilarDate(e.createdAt, "term")) {
                  numTerm = numTerm + e.amount;
                }
              });
              setTotalToday(numDay);
              setYearToday(numYear);
              setTermToday(numTerm);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (localStorage.getItem("school")) {
        getClasses(JSON.parse(localStorage.getItem("school"))._id).then(
          (pons) => {
            let newClasses = [];
            pons.map((cla) => newClasses.push(cla.name));
            setClassProp(newClasses);
            setClasses(pons);
          }
        );
        setSchool(JSON.parse(localStorage.getItem("school")));
        setLoading(false);
        getTransactions(JSON.parse(localStorage.getItem("school"))._id)
          .then((res) => {
            setTransactions(res);
            setLoading(false);
            let numDay = 0;
            let numYear = 0;
            let numTerm = 0;
            res.forEach((e) => {
              if (isSimilarDate(e.createdAt, "today")) {
                numDay = numDay + e.amount;
              }
              if (isSimilarDate(e.createdAt, "year")) {
                numYear = numYear + e.amount;
              }
              if (isSimilarDate(e.createdAt, "term")) {
                numTerm = numTerm + e.amount;
              }
            });
            setTotalToday(numDay);
            setYearToday(numYear);
            setTermToday(numTerm);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    }
  }, [scho]);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Hypnosis color="#FF0000" width="50px" height="50px" duration="1s" />
      </div>
    );
  }


  return (
    <div className="flex h-screen overflow-hidden">
      {school ? (
        <>
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              name={school.name ? school.name.split(" ")[0] : ""}
            />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <WelcomeBanner name={school.name} />

                <div className="grid grid-cols-12 gap-6">
                  <DashboardCard01 number={totalToday.toLocaleString("en")} />

                  <DashboardCard02 number={termToday.toLocaleString("en")} />

                  <DashboardCard03 number={YearToday.toLocaleString("en")} />
                  {classProps.length > 0 ? (
                    <DashboardCard06 classProp={classProps} />
                  ) : null}

                  <DashboardCard07 transactions={transactions} />
                </div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            backgroundColor: "#000",
          }}
        >
          <IoAlertCircle style={{ width: 50, height: 50 }} />
          <div>
            <p style={{ fontWeight: "bold", marginTop: 20 }}>
              NO SCHOOL AVAILABLE FOR YOU, TRY AGAIN
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
