import React, { useState, useEffect } from "react";
import { addType, getAllTypes } from "../APi/Controller";
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { IoAddCircle } from "react-icons/io5";
import TextField from "@mui/material/TextField";
import Modal from "react-modal";
import Button from "@mui/material/Button";

function FeeTypes() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [name, setName] = useState(null);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const school = JSON.parse(localStorage.getItem("school"));
    if (!school._id) {
      return (window.location.href = "/");
    }

    getAllTypes(school._id)
      .then((res) => {
        setTransactions(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const addFeeType = async () => {
    try {
      const school = JSON.parse(localStorage.getItem("school"));
      if (!name) {
        return alert("Please enter fee type");
      } else {
        let body = {
          name: name,
          school: school._id,
        };
        let res = await addType(body);
        if (res.status === 201) {
          return window.location.reload();
        } else {
          return alert("Failed to add, Please try again");
        }
      }
    } catch (error) {
      console.log(error);
      return alert("Failed to add, Please try again");
    }
  };

  const titles = ["Name", "School", "Date"];

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Hypnosis color="#FF0000" width="50px" height="50px" duration="1s" />
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          //   name={school.name ? school.name.split(" ")[0] : ""}
        />
        <div
          style={{ margin: 50 }}
          className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
        >
          <header
            style={{ display: "flex", justifyContent: "space-between" }}
            className=" px-5 py-4 border-b border-gray-100"
          >
            <h2 className="font-semibold text-gray-800">Fee Types</h2>
            <IoAddCircle
              onClick={openModal}
              style={{ fontSize: 30, cursor: "pointer" }}
              className="text-green-500"
            />
          </header>
          <div className="p-3">
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                  <tr>
                    {titles.map((title) => (
                      <th className="p-2" key={title}>
                        <div className="font-semibold text-left">{title}</div>
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* Table body */}
                {transactions.length > 0 ? (
                  <tbody className="text-sm font-medium divide-y divide-gray-100">
                    {/* Row */}
                    {transactions.map((typeOne) => (
                      <tr key={typeOne._id}>
                        <td className="p-2">
                          <div className="text-left">{typeOne.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{typeOne.school.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {new Date(typeOne.createdAt).toLocaleDateString(
                              "tz"
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div
                    style={{ margin: "20px 0" }}
                    className=" text-yellow-600 text-left"
                  >
                    No Fee Type Available
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2
          style={{ margin: "10px 0" }}
          ref={(_subtitle) => (subtitle = _subtitle)}
        >
          Add Fee Type
        </h2>

        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          sx={{ m: 1, width: "25ch" }}
        />

        <Button
          sx={{ m: 1, width: "32ch" }}
          onClick={addFeeType}
          color="primary"
          variant="contained"
        >
          Add Now
        </Button>
      </Modal>
    </div>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
  },
};

export default FeeTypes;
