import React, { useState, useEffect } from "react";
import { getTrans, getStudent } from "../APi/Controller";

const styles = {
  texts: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "bold",
    opacity: 0.8,
  },
  moreUpper: {
    opacity: 0.6,
    fontSize: 14,
  },
  moreDown: {
    fontWeight: "bold",
    fontSize: 14,
  },
};

const titles = ["Description", "Unit Amount", "Units", "Total"];
const titles2 = ["Fee Type", "Date", "Method", "Total"];

export const InvoiceToPrint = React.forwardRef((props, ref) => {
  const [student, setStudent] = useState(null);

  useEffect(() => {
    getStudent(props.studentID)
      .then((resp) => {
        setStudent(resp);
      })
      .catch((err) => console.log(err));
  }, []);

  const school = JSON.parse(localStorage.getItem("school"));

  return (
    <div ref={ref}>
      <div
        style={{ margin: 50, background: "#fff" }}
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
      >
        <div
          style={{
            padding: "10px 50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#036fce",
          }}
        >
          <div>
            <h6 style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
              INVOICE
            </h6>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <p style={styles.texts}>{school?.name}</p>
              <p style={styles.texts}>{school?.email}</p>
              <p style={styles.texts}>{school?.phone}</p>
            </div>
            <div>
              <p style={styles.texts}>{school.address}</p>
              <p style={styles.texts}>{school.district}</p>
              <p style={styles.texts}>{school.region}</p>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: 50,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: 20 }}>
            <p style={styles.moreUpper}>Billed To:</p>
            <p style={styles.moreDown}>{student?.adno}</p>
            <p style={styles.moreDown}>{student?.name}</p>
            <p style={styles.moreDown}>{student?.class.name}</p>
            <p style={styles.moreDown}>{student?.parentPhone}</p>
          </div>
          <div>
            <div>
              <p style={styles.moreUpper}>Invoice Number</p>
              <p style={styles.moreDown}>
                {Math.floor(Math.random() * 10000) + 10000}
              </p>
            </div>
            <div style={{ marginTop: 20 }}>
              <p style={styles.moreUpper}>Date Of Issue</p>
              <p style={styles.moreDown}>{new Date().toDateString()}</p>
            </div>
          </div>

          <div style={{ marginRight: 20 }}>
            <p style={{ ...styles.moreUpper, textAlign: "right" }}>
              Incoice Total
            </p>
            <p style={{ color: "#036fce", fontWeight: "bold", fontSize: 30 }}>
              TSH {(student?.totalFees - student?.paid).toLocaleString("en")}
            </p>
          </div>
        </div>

        <hr style={{ margin: "0 50px", height: 5, background: "#036fce" }} />
        <div className="overflow-x-auto" style={{ margin: 50 }}>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                {titles.map((title) => (
                  <th className="p-2" key={title}>
                    <div className="font-semibold text-left">{title}</div>
                  </th>
                ))}
              </tr>
            </thead>
            {/* Table body */}

            <tbody className="text-sm font-medium divide-y divide-gray-100">
              <tr>
                <td className="p-2">
                  <div className="text-left">School Fees</div>
                </td>

                <td className="p-2">
                  <div className="text-left">
                    {(student?.totalFees - student?.paid).toLocaleString("en")}
                  </div>
                </td>
                <td className="p-2">
                  <div className="text-left text-green-500">1</div>
                </td>

                <td className="p-2">
                  <div className="text-left text-light-blue-500">
                    {(student?.totalFees - student?.paid).toLocaleString("en")}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            margin: 60,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div>
            <p style={styles.moreUpper}>Note</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <p style={styles.moreDown}>Total (TSH): </p>
            <p style={{ marginLeft: 10, fontWeight: "bold", color: "#036fce" }}>
              {(student?.totalFees - student?.paid).toLocaleString("en")}/=
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export const ReceiptToPrint = React.forwardRef((props, ref) => {
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    getTrans(props.transID)
      .then((resp) => {
        setTransaction(resp);
      })
      .catch((err) => console.log(err));
  }, []);

  const school = JSON.parse(localStorage.getItem("school"));

  return (
    <div ref={ref}>
      <div
        style={{ margin: 50, background: "#fff" }}
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
      >
        {/* Inakuja receipt */}
        <div
          style={{
            padding: "10px 50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#58a67c",
          }}
        >
          <div>
            <h6 style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
              RECEIPT
            </h6>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <p style={styles.texts}>{school?.name}</p>
              <p style={styles.texts}>{school?.email}</p>
              <p style={styles.texts}>{school?.phone}</p>
            </div>
            <div>
              <p style={styles.texts}>{school.address}</p>
              <p style={styles.texts}>{school.district}</p>
              <p style={styles.texts}>{school.region}</p>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: 50,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: 20 }}>
            <p style={styles.moreUpper}>Paid By</p>
            <p style={styles.moreDown}>{transaction?.student?.adno}</p>
            <p style={styles.moreDown}>{transaction?.student?.name}</p>
            <p style={styles.moreDown}>{transaction?.student?.class.name}</p>
            <p style={styles.moreDown}>{transaction?.student?.parentPhone}</p>
          </div>
          <div>
            <div>
              <p style={styles.moreUpper}>Receipt Number</p>
              <p style={styles.moreDown}>{props.numb}</p>
            </div>
            <div style={{ marginTop: 20 }}>
              <p style={styles.moreUpper}>Date Of Issue</p>
              <p style={styles.moreDown}>{new Date().toDateString()}</p>
            </div>
          </div>

          <div style={{ marginRight: 20 }}>
            <p style={{ ...styles.moreUpper, textAlign: "right" }}>
              Amount Paid
            </p>
            <p style={{ color: "#58a67c", fontWeight: "bold", fontSize: 30 }}>
              TSH {transaction?.amount.toLocaleString("en")}
            </p>
          </div>
        </div>

        <hr style={{ margin: "0 50px", height: 5, background: "#58a67c" }} />
        <div className="overflow-x-auto" style={{ margin: "40px 50px 0 50px" }}>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                {titles2.map((title) => (
                  <th className="p-2" key={title}>
                    <div className="font-semibold text-left">{title}</div>
                  </th>
                ))}
              </tr>
            </thead>
            {/* Table body */}

            <tbody className="text-sm font-medium divide-y divide-gray-100">
              <tr>
                <td className="p-2">
                  <div className="text-left">{transaction?.feeType.name}</div>
                </td>

                <td className="p-2">
                  <div className="text-left">
                    {new Date(transaction?.createdAt).toLocaleDateString()}
                  </div>
                </td>

                <td className="p-2">
                  <div className="text-left ">{transaction?.paymentMethod}</div>
                </td>

                <td className="p-2">
                  <div className="text-left text-green-500">
                    {transaction?.amount.toLocaleString("en")}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            margin: "60px 0",
            width: "100%",
            borderWidth: 1,
            borderColor: "#aaa",
            borderStyle: "dashed",
          }}
        />
        <div
          style={{
            padding: "10px 50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#58a67c",
          }}
        >
          <div>
            <h6 style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>
              RECEIPT
            </h6>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <p style={styles.texts}>{school?.name}</p>
              <p style={styles.texts}>{school?.email}</p>
              <p style={styles.texts}>{school?.phone}</p>
            </div>
            <div>
              <p style={styles.texts}>{school.address}</p>
              <p style={styles.texts}>{school.district}</p>
              <p style={styles.texts}>{school.region}</p>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: 50,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: 20 }}>
            <p style={styles.moreUpper}>Paid By</p>
            <p style={styles.moreDown}>{transaction?.student?.adno}</p>
            <p style={styles.moreDown}>{transaction?.student?.name}</p>
            <p style={styles.moreDown}>{transaction?.student?.class.name}</p>
            <p style={styles.moreDown}>{transaction?.student?.parentPhone}</p>
          </div>
          <div>
            <div>
              <p style={styles.moreUpper}>Receipt Number</p>
              <p style={styles.moreDown}>{props.numb}</p>
            </div>
            <div style={{ marginTop: 20 }}>
              <p style={styles.moreUpper}>Date Of Issue</p>
              <p style={styles.moreDown}>{new Date().toDateString()}</p>
            </div>
          </div>

          <div style={{ marginRight: 20 }}>
            <p style={{ ...styles.moreUpper, textAlign: "right" }}>
              Amount Paid
            </p>
            <p style={{ color: "#58a67c", fontWeight: "bold", fontSize: 30 }}>
              TSH {transaction?.amount.toLocaleString("en")}
            </p>
          </div>
        </div>

        <hr style={{ margin: "0 50px", height: 5, background: "#58a67c" }} />
        <div className="overflow-x-auto" style={{ margin: "40px 50px 0 50px" }}>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                {titles2.map((title) => (
                  <th className="p-2" key={title}>
                    <div className="font-semibold text-left">{title}</div>
                  </th>
                ))}
              </tr>
            </thead>
            {/* Table body */}

            <tbody className="text-sm font-medium divide-y divide-gray-100">
              <tr>
                <td className="p-2">
                  <div className="text-left">{transaction?.feeType.name}</div>
                </td>

                <td className="p-2">
                  <div className="text-left">
                    {new Date(transaction?.createdAt).toLocaleDateString()}
                  </div>
                </td>

                <td className="p-2">
                  <div className="text-left ">{transaction?.paymentMethod}</div>
                </td>

                <td className="p-2">
                  <div className="text-left text-green-500">
                    {transaction?.amount.toLocaleString("en")}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
