import React, { useState, useEffect } from "react";
import { getTransactions, getClasses, getStudents } from "../APi/Controller";
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import Datepicker from "../partials/actions/Datepicker";
import CardSmall from "../components/CardSmall";

function Analytics() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [search, setSearch] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [darasa, setDarasa] = useState([]);
  const [list, setList] = useState([]);
  const [dateNew, setDateNew] = useState([]);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const school = JSON.parse(localStorage.getItem("school"));
    if (!school._id) {
      return (window.location.href = "/");
    }

    getClasses(school._id).then((fed) => setDarasa(fed));

    getTransactions(school._id)
      .then((res) => {
        setTransactions(res);
        setSearch(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    getStudents(school._id)
      .then((res) => {
        setStudents(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const updateSearch = (name) => {
    if (name === "") {
      return setSearch(transactions);
    }

    const regexp = new RegExp(name, "i");
    const filtered = transactions.filter((x) => regexp.test(x.student.name));
    return setSearch(filtered);
  };

  const handleFilter = (da) => {
    let newArray = [...list];
    if (newArray.includes(da)) {
      let filtered = newArray.filter((value, index, arr) => value !== da);
      return setList(filtered);
    } else {
      return setList([...list, da]);
    }
  };

  const filterNow = () => {
    let newSearch = [];
    if (list.length === 0) {
      return setSearch(transactions);
    }
    list.forEach((one) => {
      transactions.forEach((trans) => {
        if (trans.student.class === one) {
          newSearch.push(trans);
        }
      });
    });
    return setSearch(newSearch);
  };

  const dateChangeNew = () => {
    let newList = [];
    if (dateNew.length > 0) {
      transactions.forEach((trans) => {
        if (
          new Date(trans.createdAt) >= new Date(dateNew[0]) &&
          new Date(trans.createdAt) <= new Date(dateNew[1])
        ) {
          newList.push(trans);
        }
      });
      setSearch(newList);
    }
  };

  function isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  function isDateInThisMonth(date) {
    var currentdate = new Date();
    var cur_month = currentdate.getMonth() + 1;
    var cur_year = currentdate.getFullYear();
    return date.getMonth() + 1 === cur_month && date.getFullYear() === cur_year;
  }

  const getWeekIncome = () => {
    let total = 0;
    transactions.forEach((transa) => {
      if (isDateInThisWeek(new Date(transa.createdAt))) {
        total += transa.amount;
      }
    });
    return total;
  };

  const getMonthIncome = () => {
    let total = 0;
    transactions.forEach((transa) => {
      if (isDateInThisMonth(new Date(transa.createdAt))) {
        total += transa.amount;
      }
    });
    return total;
  };

  const totalFee = () => {
    let total = 0;
    students.forEach((stu) => {
      total += stu.totalFees;
    });
    return total;
  };

  const remainingFee = () => {
    let total = totalFee();
    let paid = 0;
    students.forEach((stu) => {
      paid += stu.paid;
    });
    return total - paid;
  };

  console.log(totalFee());

  const titles = [
    "Student",
    "Type",
    "Date",
    "Amount",
    "Total Fee",
    "Balance",
    "Paid Percent",
  ];

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Hypnosis color="#FF0000" width="50px" height="50px" duration="1s" />
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div
        style={{ marginBottom: 20 }}
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
      >
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          //   name={school.name ? school.name.split(" ")[0] : ""}
        />

        <div
          style={{ margin: "50px 50px 0 50px" }}
          className="grid grid-cols-12 gap-6"
        >
          <CardSmall
            title="Total Fees"
            smallTitle="fees"
            pesa={totalFee().toLocaleString("en")}
          />
          <CardSmall
            title="Total Unpaid"
            smallTitle={`${((remainingFee() * 100) / totalFee()).toFixed(2)}%`}
            pesa={remainingFee().toLocaleString("en")}
          />

          <CardSmall
            title="Income Per Week"
            smallTitle="fees"
            pesa={getWeekIncome().toLocaleString("en")}
          />
          <CardSmall
            title="Income Per Month"
            smallTitle="fees"
            pesa={getMonthIncome().toLocaleString("en")}
          />
        </div>

        <div
          style={{ margin: "50px 50px 0 50px" }}
          className="sm:flex sm:justify-between sm:items-center mb-8"
        >
          <DashboardAvatars />
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <FilterButton
              dara={darasa}
              onChange={handleFilter}
              filterNow={filterNow}
            />
            <Datepicker dateChange={(de) => setDateNew(de)} />
            <button
              onClick={dateChangeNew}
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <span className="hidden xs:block ml-2">Find</span>
            </button>
          </div>
        </div>

        <div
          style={{ margin: "10px 50px" }}
          className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
        >
          <header className="sm:flex sm:justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Recent Transactions</h2>
            <div className="pa2">
              <input
                style={{
                  borderRadius: 30,
                  borderWidth: 2,
                  borderColor: "#eee",
                }}
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
                type="search"
                placeholder="Search name..."
                onChange={(e) => updateSearch(e.target.value)}
              />
            </div>
          </header>
          <div className="p-3">
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                  <tr>
                    {titles.map((title) => (
                      <th className="p-2" key={title}>
                        <div className="font-semibold text-left">{title}</div>
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* Table body */}
                {search.length > 0 ? (
                  <tbody className="text-sm font-medium divide-y divide-gray-100">
                    {/* Row */}
                    {search.map((trans) => (
                      <tr
                        key={trans._id}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          (window.location = `/receipt-invoice?receipt=${trans._id}`)
                        }
                      >
                        <td className="p-2">
                          <div className="text-left">{trans.student?.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{trans.feeType.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {new Date(trans.createdAt).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-green-500">
                            {trans.amount.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {trans.student?.totalFees.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-light-blue-500">
                            {trans.balance.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-light-blue-500">
                            {(
                              (trans.amount * 100) /
                              trans.student?.totalFees
                            ).toFixed(0)}
                            %
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div
                    style={{ margin: "20px 0" }}
                    className=" text-yellow-600 text-left"
                  >
                    No transaction available
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
