import api from "../index";

export const getSchool = async (id) => {
  try {
    if (!id) {
      return {};
    }
    const school = await api.get(`/school/${id}`);
    if (school.status === 200) {
      localStorage.setItem("school", JSON.stringify(school.data));
    }
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const getTransactions = async (school) => {
  try {
    const payments = await api.get(`/payment/school/${school}`);
    return payments.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStudents = async (school) => {
  try {
    const students = await api.get(`/student/school/${school}`);
    return students.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTrans = async (id) => {
  try {
    const trans = await api.get(`/payment/${id}`);
    return trans.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStudent = async (id) => {
  try {
    const student = await api.get(`/student/${id}`);
    return student.data;
  } catch (error) {
    console.log(error);
  }
};

export const getClasses = async (school) => {
  try {
    const classes = await api.get(`/class/school/${school}`);
    return classes.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSpecificTrans = async (id) => {
  try {
    const transactions = await api.get(`/payment/student/${id}`);
    return transactions.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllTypes = async (school) => {
  try {
    const feetypes = await api.get(`/feetype/school/${school}`);
    return feetypes.data;
  } catch (error) {
    console.log(error);
  }
};

export const addType = async (body) => {
  try {
    const feetype = await api.post(`/feetype/add/`, body);
    return feetype;
  } catch (error) {
    console.log(error);
  }
};

export const updateClassFees = async (classId, body) => {
  try {
    const updated = await api.put(`/student/updateFees/${classId}`, body);
    return updated;
  } catch (error) {
    console.log(error);
  }
};

export const addTransaction = async (body) => {
  try {
    const pay = await api.post(`/payment/add/`, body);
    return pay;
  } catch (error) {
    console.log(error);
  }
};


export const sendSMSToMany = async (body) => {
  try {
    const result = await api.post("/message/sendSMS", body);
    return result;
  } catch (error) {
    console.log(error);
  }
};