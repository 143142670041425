import React from 'react';

function DashboardCard07(props) {
  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Recent Transactions</h2>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Source</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Paid</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Total fee</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Balance</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Paid Percent</div>
                </th>
              </tr>
            </thead>
            {props.transactions ? (
              <tbody className="text-sm font-medium divide-y divide-gray-100">
                {props.transactions.slice(0, 6).map((trans) => (
                  <tr key={trans._id}>
                    <td className="p-2">
                      <div className="text-left">{trans.student?.name}</div>
                    </td>

                    <td className="p-2">
                      <div className="text-left text-green-500">
                        {trans.amount.toLocaleString("en")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        {trans.student?.totalFees.toLocaleString("en")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left text-light-blue-500">
                        {trans.balance.toLocaleString("en")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left text-light-blue-500">
                        {(
                          (trans.amount * 100) /
                          trans.student?.totalFees
                        ).toFixed(0)}
                        %
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <div className="text-left">No Transaction to show</div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard07;
