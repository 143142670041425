import React, { useState, useEffect } from "react";
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import { getSpecificTrans, getStudent, sendSMSToMany } from "../APi/Controller";
import { useLocation } from "react-router-dom";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";

const OneStudent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [student, setStudent] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [isSending, setIsSending] = useState(false);

  let scho = useLocation().search.split("=")[1];

  useEffect(() => {
    const school = JSON.parse(localStorage.getItem("school"));
    if (!school._id) {
      return (window.location.href = "/");
    }

    getStudent(scho)
      .then((resp) => setStudent(resp))
      .catch((err) => console.log(err));

    getSpecificTrans(scho)
      .then((res) => {
        setTransactions(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [scho]);

  //hii ni code ya kugenerate sms
  const pataMessage = async () => {
    let miamala = [];
    transactions.map((transaction) => {
      miamala.push([
        transaction.feeType.name,
        new Date(transaction.createdAt).toLocaleDateString(),
        transaction.amount.toLocaleString("en"),
      ]);
    });

    const text = `Habari ndugu Mzazi/Mlezi.
      \n\n${student.school.name} inatoa taarifa ya malipo ya ${student.name}:-
      \n\nAda = ${student.totalFees.toLocaleString("en")} Tsh,
      \nIliyolipwa = ${student.paid.toLocaleString("en")} Tsh,
      \nBalance = ${(student.totalFees - student.paid).toLocaleString(
        "en"
      )} Tsh.
      \n\nMiamala
      \n${miamala.map(
        (mi, index) => `${index + 1} - ${mi[0]}, ${mi[1]}, ${mi[2]} Tsh  \n`
      )}
  \n\nUtaweza kujua maendeleo ya mwanao kupitia app ya Bara. Kama huna unashauriwa kupakua sasa.\n\nhttps://onelink.to/baraapp 
      `;

    return text;
  };

  //imeisha code

  const sendSMS = async () => {
    try {
      setIsSending(true);
      const message = await pataMessage();
      let phone;
      if (student.parentPhone.toString()[0] !== "2") {
        phone = `255${student.parentPhone}`;
      } else {
        phone = `${student.parentPhone}`;
      }
      const body = {
        to: Number(phone),
        text: message,
      };
      const feedback = await sendSMSToMany(body);
      setIsSending(false);
      if (feedback.status === 200) {
        return alert("SMS sent successfully");
      } else {
        return alert("Failed to send, try again");
      }
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Hypnosis color="#FF0000" width="50px" height="50px" duration="1s" />
      </div>
    );
  }

  const titles = [
    "Fee Type",
    "Total Fee(TSH)",
    "Amount Paid(TSH)",
    "Balance(TSH)",
    "Paid Percent",
    "Date",
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="flex sm:justify-between">
          <div
            style={{ margin: "10px 0px 10px 50px", width: "50%" }}
            className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
          >
            <header className="sm:flex sm:justify-between px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">
                {student?.name}'s Profile
              </h2>
            </header>
            <div className="p-3">
              <div className="flex justify-between">
                <p>Admision No:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {student?.adno}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Full Name:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {student?.name}
                </p>
              </div>
              <div className="flex justify-between">
                <p>School:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {student?.school.name}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Class:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {student?.class.name}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Parent Phone:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  {student?.parentPhone}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Status:</p>
                <p
                  className="text-green-500"
                  style={{ marginLeft: 5, fontWeight: "bold" }}
                >
                  {student?.status}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ margin: "10px 50px 10px 0", width: "38%" }}
            className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
          >
            <header className="sm:flex sm:justify-between px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">More Actions</h2>
            </header>
            <div className="p-3">
              <div className="flex justify-between">
                <p>Total Fees:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  TSH {student?.totalFees.toLocaleString("en")}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Total Paid:</p>
                <p
                  style={{ marginLeft: 5, fontWeight: "bold" }}
                  className="text-green-500"
                >
                  TSH {student?.paid.toLocaleString("en")}
                </p>
              </div>
              <div className="flex justify-between ">
                <p>Balance:</p>
                <p style={{ marginLeft: 5, fontWeight: "bold" }}>
                  TSH{" "}
                  {(student?.totalFees - student?.paid).toLocaleString("en")}
                </p>
              </div>

              <div className="flex justify-center p-3 ">
                {isSending ? (
                  <div
                    style={{
                      width: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Hypnosis
                      color="#00FF00"
                      width="20px"
                      height="20px"
                      duration="1s"
                    />
                  </div>
                ) : (
                  <button
                    onClick={sendSMS}
                    style={{ marginRight: 10, paddingRight: 18 }}
                    className="btn bg-green-500 hover:bg-green-800 text-white"
                  >
                    <span className="hidden xs:block ml-2">Send SMS</span>
                  </button>
                )}
                <button
                  onClick={() =>
                    (window.location = `/receipt-invoice?invoice=${student._id}`)
                  }
                  className="btn bg-light-blue-500 hover:bg-light-blue-600 text-white"
                >
                  <span className="hidden xs:block ml-2">Generate Invoice</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ margin: "10px 50px" }}
          className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
        >
          <header className="sm:flex sm:justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">
              {student?.name}'s Transactions
            </h2>
          </header>
          <div className="p-3">
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                  <tr>
                    {titles.map((title) => (
                      <th className="p-2" key={title}>
                        <div className="font-semibold text-left">{title}</div>
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* Table body */}
                {transactions.length > 0 ? (
                  <tbody className="text-sm font-medium divide-y divide-gray-100">
                    {/* Row */}
                    {transactions.map((trans) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        key={trans._id}
                        onClick={() =>
                          (window.location = `/receipt-invoice?receipt=${trans._id}`)
                        }
                      >
                        <td className="p-2">
                          <div className="text-left">{trans.feeType.name}</div>
                        </td>

                        <td className="p-2">
                          <div className="text-left">
                            {trans.student.totalFees.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-green-500">
                            {trans.amount.toLocaleString("en")}
                          </div>
                        </td>

                        <td className="p-2">
                          <div className="text-left text-light-blue-500">
                            {trans.balance.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-light-blue-500">
                            {(
                              (trans.amount * 100) /
                              trans.student.totalFees
                            ).toFixed(0)}
                            %
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {new Date(trans.createdAt).toLocaleDateString()}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div
                    style={{ margin: "20px 0" }}
                    className=" text-yellow-600 text-left"
                  >
                    No transaction available
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneStudent;
