import React, { useState, useEffect } from "react";
import {
  getStudents,
  getAllTypes,
  addTransaction,
  getClasses,
  updateClassFees,
  getTransactions,
  sendSMSToMany,
} from "../APi/Controller";
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import TextField from "@mui/material/TextField";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import Modal1 from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import InputAdornment from "@mui/material/InputAdornment";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { IoCreateOutline, IoSend } from "react-icons/io5";

Modal.setAppElement("#root");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Students() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [feeTypes, setFeeTypes] = useState([]);
  const [classes, setClasses] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isFetching, setFetching] = useState(false);
  const [initial, setInitial] = useState(0);
  const [selectedStudent, setselectedStudent] = useState(null);
  const [name, setName] = useState(null);
  const [darasa, setDarasa] = useState([]);
  const [search, setSearch] = useState([]);
  const [amount, setAmount] = useState(0);
  const [method, setMethod] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [erro, setErro] = useState(null);
  const [typ, setTyp] = useState(null);
  const [theClass, setTheClass] = useState(null);
  const [kiasi, setKiasi] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [allSelect, setAllSelect] = useState(false);
  const [isSendingToAll, setIsSendingToAll] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const school = JSON.parse(localStorage.getItem("school"));
    if (!school._id) {
      return (window.location.href = "/");
    }
    getTransactions(school._id).then((response) => setTransactions(response));
    getClasses(school._id).then((fed) => {
      let arr = [];

      fed.map((ty) => {
        arr.push({
          value: ty._id,
          label: ty.name,
        });
      });
      setClasses(arr);
      setDarasa(fed);
    });
    getStudents(school._id)
      .then((res) => {
        setStudents(res);
        setSearch(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    getAllTypes(school._id)
      .then((res) => {
        let arr = [];

        res.map((ty) => {
          arr.push({
            value: ty._id,
            label: ty.name,
          });
        });
        setFeeTypes(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  //hii ni code ya kugenerate sms
  const pataMessage = async (student) => {
    let miamala = [];
    let newTrans = [];
    const school = JSON.parse(localStorage.getItem("school"));
    transactions.forEach((trans) => {
      if (trans.student._id === student._id) {
        newTrans.push(trans);
      }
    });
    newTrans?.map((transaction) => {
      miamala.push([
        transaction.feeType.name,
        new Date(transaction.createdAt).toLocaleDateString(),
        transaction.amount.toLocaleString("en"),
      ]);
    });

    const text = `Habari ndugu Mzazi/Mlezi.
      \n\n${school.name} inatoa taarifa ya malipo ya ${student.name}:-
      \n\nAda = ${student.totalFees.toLocaleString("en")} Tsh,
      \nIliyolipwa = ${student.paid.toLocaleString("en")} Tsh,
      \nBalance = ${(student.totalFees - student.paid).toLocaleString(
        "en"
      )} Tsh.
      \n\nMiamala
      \n${miamala.map(
        (mi, index) => `${index + 1} - ${mi[0]}, ${mi[1]}, ${mi[2]} Tsh  \n`
      )}
  \n\nUtaweza kujua maendeleo ya mwanao kupitia app ya Bara. Kama huna unashauriwa kupakua sasa.\n\nhttps://onelink.to/baraapp 
      `;

    return text;
  };

  const sendSMSToAll = () => {
    setIsSendingToAll(true);

    search.map(async (mwanafunzi) => {
      try {
        if (mwanafunzi.parentPhone === null) {
          return;
        }
        if (mwanafunzi.totalFees <= mwanafunzi.paid) {
          return;
        }

        const message = await pataMessage(mwanafunzi);
        let phone;
        if (mwanafunzi?.parentPhone.toString()[0] !== "2") {
          phone = `255${mwanafunzi.parentPhone}`;
        } else {
          phone = `${mwanafunzi.parentPhone}`;
        }
        const body = {
          to: Number(phone),
          text: message,
        };
        await sendSMSToMany(body);
        setIsSendingToAll(false);

        return alert("SMS sent successfully");
      } catch (error) {
        console.log(error);
      }
    });
  };

  const updateSearch = (name) => {
    setIsSendingToAll(false);
    if (name === "") {
      return setSearch(students);
    }

    const regexp = new RegExp(name, "i");
    const filtered = students.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const handleFilter = (da) => {
    setIsSendingToAll(false);
    let newArray = [...list];
    if (newArray.includes(da)) {
      let filtered = newArray.filter((value, index, arr) => value !== da);
      return setList(filtered);
    } else {
      return setList([...list, da]);
    }
  };

  const filterNow = () => {
    let newSearch = [];
    setIsSendingToAll(false);
    if (list.length === 0) {
      return setSearch(students);
    }
    list.forEach((one) => {
      students.forEach((trans) => {
        if (trans.class._id === one) {
          newSearch.push(trans);
        }
      });
    });
    return setSearch(newSearch);
  };

  const handleTrans = async () => {
    try {
      if (!amount) {
        setErro("numbers only");
        setIsErr(true);
      }
      console.log(method, typ);
      if (!method || !typ) {
        return alert("Enter Payment method and Type");
      }
      const school = JSON.parse(localStorage.getItem("school"));

      let body = {
        feeType: typ,
        school: school._id,
        method: method,
        student: selectedStudent,
        amount: Number(amount),
        initialBalance: initial,
      };

      const res = await addTransaction(body);
      if (res.status === 201) {
        return window.location.reload();
      } else {
        return alert("Failed to add, Please try again");
      }
    } catch (error) {
      console.log(error);
      return alert("Failed to add, Please try again");
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const updateFee = async () => {
    try {
      if (!kiasi || !theClass) {
        return alert("Select class with total fee amount");
      }
      setFetching(true);
      const body = {
        fee: Number(kiasi),
      };
      const response = await updateClassFees(theClass, body);
      if (response.status === 202) {
        setFetching(false);
        return window.location.reload();
      } else {
        setFetching(false);
        return alert("Failed to update. Please try again");
      }
    } catch (error) {
      setFetching(false);
      console.log(error);
    }
  };

  const titles = [
    "Name",
    "Class",
    "Total Fee(TSH)",
    "Amount Paid(TSH)",
    "Balance(TSH)",
    "Paid Percent",
    "Action",
  ];

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Hypnosis color="#FF0000" width="50px" height="50px" duration="1s" />
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div
          style={{ margin: "50px 50px 0 50px" }}
          className="sm:flex sm:justify-between sm:items-center mb-8"
        >
          <DashboardAvatars />
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <div
              onClick={handleOpen}
              style={{
                cursor: "pointer",
                width: 40,
                height: 40,
                background: "white",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoCreateOutline style={{ fontSize: 20, color: "black" }} />
            </div>
            <FilterButton
              dara={darasa}
              onChange={handleFilter}
              filterNow={filterNow}
            />
          </div>
        </div>
        <div
          style={{ margin: "10px 50px" }}
          className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
        >
          <header className="sm:flex sm:justify-between px-5 py-4 border-b border-gray-100">
            <div className="sm:flex sm:justify-between ">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  value={allSelect}
                  className="form-checkbox"
                  onChange={() => setAllSelect(!allSelect)}
                />
                <span className="text-sm font-medium ml-2">Select All</span>
              </label>
              {allSelect ? (
                <>
                  {!isSendingToAll ? (
                    <div
                      onClick={sendSMSToAll}
                      style={{
                        cursor: "pointer",
                        width: 30,
                        height: 30,
                        background: "#eee",
                        borderRadius: 5,
                        margin: "8px 16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoSend style={{ fontSize: 15, color: "blue" }} />
                    </div>
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        width: 30,
                        height: 30,
                        background: "#eee",
                        borderRadius: 5,
                        margin: "8px 16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Hypnosis
                        color="blue"
                        width="15px"
                        height="15px"
                        duration="1s"
                      />
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <h2
              style={{ marginTop: 10 }}
              className="font-semibold text-gray-800"
            >
              Students
            </h2>
            <div className="pa2">
              <input
                style={{
                  borderRadius: 30,
                  borderWidth: 2,
                  borderColor: "#eee",
                }}
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
                type="search"
                placeholder="Search name..."
                onChange={(e) => updateSearch(e.target.value)}
              />
            </div>
          </header>
          <div className="p-3">
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                  <tr>
                    {titles.map((title) => (
                      <th className="p-2" key={title}>
                        <div className="font-semibold text-left">{title}</div>
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* Table body */}
                {search.length > 0 ? (
                  <tbody className="text-sm font-medium divide-y divide-gray-100">
                    {/* Row */}
                    {search.map((student) => (
                      <tr key={student._id} style={{ cursor: "pointer" }}>
                        <td
                          className="p-2"
                          onClick={() =>
                            (window.location = `/one?id=${student._id}`)
                          }
                        >
                          <div className="text-left">{student.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{student.class.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {student.totalFees.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-green-500">
                            {student.paid.toLocaleString("en")}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {(student.totalFees - student.paid).toLocaleString(
                              "en"
                            )}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-light-blue-500">
                            {((student.paid * 100) / student.totalFees).toFixed(
                              0
                            )}
                            %
                          </div>
                        </td>
                        <td className="p-2">
                          <button
                            onClick={() => {
                              setselectedStudent(student._id);
                              setName(student.name);
                              setInitial(student.feeBalance);
                              openModal();
                            }}
                            className="text-left text-light-blue-500"
                          >
                            Pay
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div
                    style={{ margin: "20px 0" }}
                    className=" text-yellow-600 text-left"
                  >
                    No transaction available
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2
          style={{ margin: "10px 0" }}
          ref={(_subtitle) => (subtitle = _subtitle)}
        >
          Payment Received from {name}
        </h2>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Fee Type"
          onChange={(val) => setTyp(val.target.value)}
          sx={{ m: 1, width: "25ch" }}
        >
          {feeTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-basic"
          label="Method"
          variant="outlined"
          onChange={(e) => setMethod(e.target.value)}
          sx={{ m: 1, width: "25ch" }}
        />
        <TextField
          error={isErr}
          label="Amount"
          type="number"
          id="outlined-error-helper-text"
          sx={{ m: 1, width: "25ch" }}
          style={{ outlineWidth: 0 }}
          onChange={(e) => {
            setAmount(e.target.value);
            setErro(null);
            setIsErr(false);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">TSH</InputAdornment>
            ),
          }}
          variant="outlined"
          helperText={isErr ? erro : null}
        />
        <Button
          sx={{ m: 1, width: "32ch" }}
          onClick={handleTrans}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
      </Modal>

      <Modal1
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit total fees per class
          </Typography>
          <TextField
            id="outlined-select-currency"
            select
            label="Select Class"
            onChange={(val) => setTheClass(val.target.value)}
            sx={{ m: 1, width: "27.5ch" }}
          >
            {classes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <div className="p-2">
            <input
              style={{
                borderRadius: 5,
                borderWidth: 2,
                borderColor: "#eee",
              }}
              onChange={(e) => setKiasi(e.target.value)}
              className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
              type="search"
              placeholder="Enter Amount..."
            />
          </div>
          {!isFetching ? (
            <Button
              onClick={updateFee}
              sx={{ m: 1, width: "35ch" }}
              variant="text"
            >
              Confirm
            </Button>
          ) : (
            <div
              style={{
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <Hypnosis
                color="#FF0000"
                width="20px"
                height="20px"
                duration="1s"
              />
            </div>
          )}
        </Box>
      </Modal1>
    </div>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
  },
};

export default Students;
