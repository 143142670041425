import React from 'react';
import SearchModal from './header/SearchModal';
import Notifications from './header/Notifications';
import Help from './header/Help';
import UserMenu from './header/UserMenu';

function Header({ sidebarOpen, setSidebarOpen, name }) {
  return (
    <header className="sticky top-0 bg-white border-b border-gray-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <h2 style={{ fontWeight: "bold" }} className=" text-green-500">
              School Fee Management System
            </h2>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <SearchModal />
            {/* <Notifications />
            <Help /> */}
            {/*  Divider */}
            <hr className="w-px h-6 bg-gray-200 mx-3" />
            <UserMenu name={name} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;